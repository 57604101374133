//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { browserName, browserVersion } from "react-device-detect";
import logo from '../../assets/logo-home.png';
//import homeAnimation from '../../assets/home-animation.gif';
import homeAnimation from '../../assets/home-animation.json';
import create from '../../assets/icons/create.png';
import share from '../../assets/icons/share.png';
import money from '../../assets/icons/money.png';
import lineDivider from '../../assets/icons/line-divider.png';
import lineDividerRight from '../../assets/icons/line-divider-right.png';
import pageComments from '../../assets/page-comments.JPG';
import demoChat from '../../assets/demo-chat.gif';
import Lottie from 'react-lottie';
import Api from '../../services/Api'
import Loading from '../../components/Loading';
import './styles.css';
import '../../App.css';

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(true);
  const [userLogado, setUserLogado] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: homeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const onScroll = e => {
      //console.log(`${browserName} ${browserVersion}`);
      if(browserName === "Mobile Safari"){
        setScrollTop(e.target.body.scrollTop);
        //console.log(e.target.body.scrollTop)
      } else {
        setScrollTop(e.target.documentElement.scrollTop);
        //console.log(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  
  return (
    <div id="homeIndex">
      {toggleMenu ?
        <div id="menu">
          <ul className="text-center">
            <li><a href="#bodyKnow" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Conheça </span>
            </a></li>
            <li><a href="#bodyHowWorks" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Como Funciona </span>
            </a></li>
            <li><a href="#bodyTax" onClick={() => setToggleMenu(!toggleMenu)}>
              <span> Taxas </span>
            </a></li>
            <li><a href="/creator/login">
              <span> Entrar </span>
            </a></li>
            <li><a href="./creator/register" className="btn btn-default" style={{fontSize: 25}}>
              <span> Criar Conta </span>
            </a></li>
          </ul>
        </div>
      : <></> }
      <div id="navbar" className={scrollTop > 150 ? "onScroll" : ""}>
        <div className="container">
          <div className="row v-center">
            <div className="col-4 logo">
              <img src={logo}/>
            </div>
            <div className="col-8 text-right hidden-xs hidden-sm hidden-md">
              <a href="#bodyKnow">
                <span> Conheça </span>
              </a>
              <a href="#bodyHowWorks">
                <span> Como Funciona </span>
              </a>
              <a href="#bodyTax">
                <span> Taxas </span>
              </a>
              <a href="/creator/login">
                <span> Entrar </span>
              </a>
              <a href="./creator/register" className="btn btn-default">
                <span> Criar Conta </span>
              </a>
            </div>
            <div className="col-8 text-right visible-xs visible-sm visible-md">
              <button type="button" className="btn btn-default btnMenu" onClick={() => setToggleMenu(!toggleMenu)}>
                {toggleMenu ?
                  <i className="fa fa-times fa-lg"> </i>
                : 
                  <i className="fa fa-bars fa-lg"> </i> 
                } 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bodyIndex v-center">
        <div className="container">
          <div className="row v-center">
            <div className="col-md-5 col-12">
              <h1>Receba comentários pagos via PIX e fuja das taxas abusivas das plataformas!</h1>
              <p>Centralize a sua audiência em um só lugar, receba comentários pagos de qualquer plataforma!</p>
              <div className="hidden-xs">
                <br /><br /><br />
                <div className="row">
                  <div className="col-md-6 col-12">
                    <a href="./creator/register" className="btn btn-default-outline col-12">
                      <i className="fa fa-rocket fa-lg"> </i>
                      <span style={{marginLeft: 5}}> Criar Conta </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12 text-center">
              <div className="homeAnimation">
                {/*<img src={homeAnimation} width="100%"/>*/}
                <Lottie options={defaultOptions}
                  //height={100}
                  //width={100}
                  //style={{ marginTop: '30%'}}
                  //isStopped={this.state.isStopped}
                  //isPaused={this.state.isPaused}
                />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="visible-xs">
                <br /><br />
                <a href="#" target="_Blank" className="btn btn-default-outline col-12">
                  <i className="fa fa-rocket fa-lg"> </i>
                  <span style={{marginLeft: 5}}> Criar Conta </span>
                </a>
                <br />
              </div>
            </div>
          </div>
          <div style={{ backgroundImage: `url(${lineDivider})` }} className="lineDivider"></div>
        </div>
      </div>
      <div id="bodyKnow">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Conheça!</h1>
              <span>Veja o que podemos lhe oferecer!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row v-center">
            <div className="col-12 col-md-4 text-center">
              {/*<img src={pageComments} width="100%" style={{marginBottom: 15}}/>*/}
              <img src={demoChat} id="imageDemo" width="100%"/>
            </div>
            <div className="col-12 col-md-8 text-center">
              <div className="itemKnow v-center">
                <div className="col-2 text-center" >
                  <i className="fa fa-images fa-2x"></i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Comentários em texto, imagem e vídeo!</p>
                  <span>Possibilite a interação com a sua audiência de direfentes formas.</span>  
                </div>
              </div>
              <div className="itemKnow v-center">
                <div className="col-2 text-center" >
                  <i className="fa fa-qrcode fa-2x"></i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Pagamentos via PIX!</p>
                  <span>As transações são aprovadas de forma rápida e segura!</span>  
                </div>
              </div>
              <div className="itemKnow v-center">
                <div className="col-2 text-center" >
                  <i className="fa fa-dollar-sign fa-2x"></i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Fuja das taxas abusivas!</p>
                  <span>Diferente das plataformas, aqui as taxas são coerentes!</span>  
                </div>
              </div>
              <div className="itemKnow v-center">
                <div className="col-2 text-center" >
                  <i className="fa fa-comments fa-2x"> </i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Página exclusiva para os comentários!</p>
                  <span>Centralize todos os comentários pagos em um só lugar!</span>  
                </div>
              </div>
              <div className="itemKnow v-center">
                <div className="col-2 text-center">
                  <i className="fa fa-video fa-2x"> </i>
                </div>
                <div className="col-10 text-left">
                  <p className="title">Integração com softwares de transmissão!</p>
                  <span>As mensagens são adicionadas automaticamente na transmissão!</span>  
                </div>
                <span style={{position: "absolute",right: 0, bottom: 0, margin: "17px 25px", fontWeight: "bold", color: "#009ce2"}}>EM BREVE</span>  
              </div>
            </div>
          </div>
          <div style={{ backgroundImage: `url(${lineDividerRight})` }} className="lineDivider"></div>
        </div>
      </div>
      <div id="bodyHowWorks" style={{marginTop: -200}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Como Funciona?</h1>
              <span>Veja como é simples utilizar o PayChat!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 text-center">
              <img src={create} width="100%"/>
              <p className="title">Crie a Página</p>
              <span>Crie a página de comentários da transmissão e receba o link de compartilhamento</span>
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={share} width="100%"/>
              <p className="title">Compartilhe o Link</p>
              <span>Compartilhe o link da página de comentários em sua transmissão ao vivo.</span>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <img src={money} width="100%"/>
              <p className="title">Receba Comentários Pagos</p>
              <span>Receba de forma centralizadas todos os comentários pagos em um só lugar!</span>  
            </div>
          </div>
          <div className="col-12 text-center">
            <br /><br /><br />
            <a href="./creator/register" className="btn btn-default-outline col-12 col-md-4">
              <i className="fa fa-rocket fa-lg"> </i>
              <span style={{marginLeft: 5}}> Criar Conta </span>
            </a>
            <br />
          </div>
          <div style={{ backgroundImage: `url(${lineDivider})` }} className="lineDivider"></div>
        </div>
      </div>
      <div id="bodyTax" style={{marginTop: -200}}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Taxas!</h1>
              <span>Confira as nossas taxas de intermediação!</span>  
              <br /><br /><br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6 text-center">
              <div className="itemTax active text-center">
                <div className="priceItemTax v-center">
                  <p className="title"> 4.90 <i className="fa fa-percent"> </i> </p>
                </div>
                <p className="title" style={{marginTop: 25}}>Liberação em 48h</p>
                <span>Liberação dos valores para saque em 48 horas!</span>  
                <br /><br />
                <a href="./creator/register" className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Começar Agora </span>
                </a>
              </div>
            </div>
            {/*<div className="col-12 col-md-4 text-center">
              <div className="itemTax text-center">
                <div className="priceItemTax v-center">
                  <p className="title"> 6.90 <i className="fa fa-percent"> </i></p>
                </div>
                <p className="title" style={{marginTop: 25}}>Liberação Quinzenal</p>
                <span>Liberação dos valores para saque em 15 dias!</span>  
                <br /><br />
                <a href="./creator/register" className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Começar Agora </span>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <div className="itemTax text-center">
                <div className="priceItemTax v-center">
                  <p className="title"> 8.90 <i className="fa fa-percent"> </i> </p>
                </div>
                <p className="title" style={{marginTop: 25}}>Liberação em 24H</p>
                <span>Liberação dos valores para saque em até 24h!</span>  
                <br /><br />
                <a href="./creator/register" className="btn btn-default col-12 col-md-10">
                  <span style={{marginLeft: 5}}> Começar Agora </span>
                </a>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container">
          <br /><hr />
          <div className="row">
            <div className="col-12 text-center">
              <p>Todos os direitos reservados © <a href="https://tweezer.com.br" target="_Blank">Tweezer | Agência Digital</a></p>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
 
}